import { IMandator } from 'main/schemas/Mandator';

import { execGet } from '../utils/RequestUtils';

export async function getMandatorInformation(
  mandatorId: number
): Promise<IMandator> {
  const response = await execGet<IMandator>(`mandators/${mandatorId}`);
  return response;
}
